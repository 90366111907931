//权限

class authority {
	constructor(parent) {
		this.parent = parent
	}

	m_main() {
		const that = this;
		// that.test_login();  
		
		let currentRoute = that.parent.$router.currentRoute;
		
		 
	}
	
	test_login(){
		const that = this; 
		
		let user_state = localStorage.getItem("user_state");
		console.log("333");
		console.log(user_state);
		if(user_state != 1){
			this.parent.$notify({
				title: '提示',
				message: "登陆过期 即将跳转到 登陆",
				type: 'success'
			});
			
			setTimeout(function (){ 
			    that.parent.$router.push({path:'/login',query:{}}); 
			}, 2000); 
		}
	}
	
	set_quanxian2(){
		const that = this; 
		let quanxian = JSON.parse(localStorage.getItem('quanxian'));
		
		that.quanxian2 = {};
		
		for(let i = 0;i < quanxian.length;i++){
			if(quanxian[i]["user_auth_id"] == "10001001"){
				//报表 发货报表 发货报表
				that.quanxian2["10001"] = true;
				that.quanxian2["10001001"] = true;
				//报表
				that.quanxian2["10000"] = true;
			}
			
			//销售 -------------------------------------------------------------
			
			//订单 -------------------------------
			
			if(quanxian[i]["user_auth_id"] == "20001001"){
				//销售 订单 未完成订单
				that.quanxian2["20001"] = true;
				that.quanxian2["20001001"] = true;
				//销售
				that.quanxian2["20000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "20001002"){
				//销售 订单 前置
				that.quanxian2["20001"] = true;
				that.quanxian2["20001002"] = true;
				//销售
				that.quanxian2["20000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "20001003"){
				//销售 订单 报价
				that.quanxian2["20001"] = true;
				that.quanxian2["20001003"] = true;
				//销售
				that.quanxian2["20000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "20001004"){
				//销售 订单 官网采购
				that.quanxian2["20001"] = true;
				that.quanxian2["20001004"] = true;
				//销售
				that.quanxian2["20000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "20001005"){
				//销售 订单 退货
				that.quanxian2["20001"] = true;
				that.quanxian2["20001005"] = true;
				//销售
				that.quanxian2["20000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "20001006"){
				//销售 订单 全部订单
				that.quanxian2["20001"] = true;
				that.quanxian2["20001006"] = true;
				//销售
				that.quanxian2["20000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "20001007"){
				//销售 订单 咨询
				that.quanxian2["20001"] = true;
				that.quanxian2["20001007"] = true;
				//销售
				that.quanxian2["20000"] = true;
			}
			
			//销售库存 -------------------------------
			
			if(quanxian[i]["user_auth_id"] == "20002001"){
				//销售 销售库存 发货
				that.quanxian2["20002"] = true;
				that.quanxian2["20002001"] = true;
				//销售库存
				that.quanxian2["20000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "20002002"){
				//销售 销售库存 已发货
				that.quanxian2["20002"] = true;
				that.quanxian2["20002002"] = true;
				//销售库存
				that.quanxian2["20000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "20002003"){
				//销售 销售库存 库存
				that.quanxian2["20002"] = true;
				that.quanxian2["20002003"] = true;
				//销售库存
				that.quanxian2["20000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "20002004"){
				//销售 销售库存 损耗
				that.quanxian2["20002"] = true;
				that.quanxian2["20002004"] = true;
				//销售库存 
				that.quanxian2["20000"] = true;
			}
			
			//库存 -------------------------------------------------------------
			
			//库存管理 -------------------------------
			
			if(quanxian[i]["user_auth_id"] == "30001001"){
				//库存 库存管理 验货
				that.quanxian2["30001"] = true;
				that.quanxian2["30001001"] = true;
				//库存
				that.quanxian2["30000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "30001002"){
				//库存 库存管理 发货
				that.quanxian2["30001"] = true;
				that.quanxian2["30001002"] = true;
				//库存
				that.quanxian2["30000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "30001003"){
				//库存 库存管理 称重
				that.quanxian2["30001"] = true;
				that.quanxian2["30001003"] = true;
				//库存
				that.quanxian2["30000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "30001004"){
				//库存 库存管理 退货件
				that.quanxian2["30001"] = true;
				that.quanxian2["30001004"] = true;
				//库存
				that.quanxian2["30000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "30001005"){
				//库存 库存管理 问题件
				that.quanxian2["30001"] = true;
				that.quanxian2["30001005"] = true;
				//库存
				that.quanxian2["30000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "30001006"){
				//库存 库存管理 库存
				that.quanxian2["30001"] = true;
				that.quanxian2["30001006"] = true;
				//库存
				that.quanxian2["30000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "30001007"){
				//库存 库存管理 损耗
				that.quanxian2["30001"] = true;
				that.quanxian2["30001007"] = true;
				//库存
				that.quanxian2["30000"] = true;
			}
			
		  
			//库存设置 -------------------------------
			
			if(quanxian[i]["user_auth_id"] == "30002001"){
				//库存 库房设置 库房设置
				that.quanxian2["30002"] = true;
				that.quanxian2["30002001"] = true;
				//库存
				that.quanxian2["30000"] = true;
			}
			
			
			
			
			
			
			//财务 -------------------------------------------------------------
			
			//资金流水 -------------------------------
			
			if(quanxian[i]["user_auth_id"] == "40001001"){
				//库存 资金流水 资金流水
				that.quanxian2["40001"] = true;
				that.quanxian2["40001001"] = true;
				//财务
				that.quanxian2["40000"] = true;
			}
			 
			
			//应收账款 -------------------------------
			
			if(quanxian[i]["user_auth_id"] == "40002001"){
				//财务 应收账款 应收账款
				that.quanxian2["40002"] = true;
				that.quanxian2["40002001"] = true;
				//财务
				that.quanxian2["40000"] = true;
			}
			
			//应付账款 -------------------------------
			
			if(quanxian[i]["user_auth_id"] == "40003001"){
				//库存 应付账款 应付账款
				that.quanxian2["40003"] = true;
				that.quanxian2["40003001"] = true;
				//财务
				that.quanxian2["40000"] = true;
			}
			
			
			
			
			//设置 -------------------------------------------------------------
			
			//系统设置 -------------------------------
			
			if(quanxian[i]["user_auth_id"] == "50001001"){
				//设置 系统设置 系统设置
				that.quanxian2["50001"] = true;
				that.quanxian2["50001001"] = true;
				//设置
				that.quanxian2["50000"] = true;
			}
			
			 //店铺设置 -------------------------------
			 
			 if(quanxian[i]["user_auth_id"] == "50002001"){
			 	//设置 店铺设置 店铺设置
			 	that.quanxian2["50002"] = true;
			 	that.quanxian2["50002001"] = true;
			 	//设置
			 	that.quanxian2["50000"] = true;
			 }
			
			//系统设置 -------------------------------
			
			if(quanxian[i]["user_auth_id"] == "50003001"){
				//设置 用户设置 用户设置
				that.quanxian2["50003"] = true;
				that.quanxian2["50003001"] = true;
				//设置
				that.quanxian2["50000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "50003002"){
				//设置 用户设置 用户设置
				that.quanxian2["50003"] = true;
				that.quanxian2["50003002"] = true;
				//设置
				that.quanxian2["50000"] = true;
			}
			
			 
			//品牌设置 -------------------------------
			
			if(quanxian[i]["user_auth_id"] == "50004001"){
				//设置 品牌设置 品牌设置
				that.quanxian2["50004"] = true;
				that.quanxian2["50004001"] = true;
				//设置
				that.quanxian2["50000"] = true;
			}
				
			//吊牌设置 -------------------------------
			
			if(quanxian[i]["user_auth_id"] == "50005001"){
				//设置 吊牌设置 吊牌设置
				that.quanxian2["50005"] = true;
				that.quanxian2["50005001"] = true;
				//设置
				that.quanxian2["50000"] = true;
			}	
			
			//工具设置 -------------------------------
			
			if(quanxian[i]["user_auth_id"] == "50006001"){
				//设置 工具设置 品牌设置
				that.quanxian2["50006"] = true;
				that.quanxian2["50006001"] = true;
				//设置
				that.quanxian2["50000"] = true;
			}	
			
			if(quanxian[i]["user_auth_id"] == "50006002"){
				//设置 工具设置 专柜设置
				that.quanxian2["50006"] = true;
				that.quanxian2["50006002"] = true;
				//设置
				that.quanxian2["50000"] = true;
			}	
			
			//专柜设置 -------------------------------
			
			if(quanxian[i]["user_auth_id"] == "50007001"){
				//渠道 专柜设置 专柜设置
				that.quanxian2["50007"] = true;
				that.quanxian2["50007001"] = true;
				//渠道
				that.quanxian2["50000"] = true;
			}
			
			//快递设置 -------------------------------
			
			if(quanxian[i]["user_auth_id"] == "50008001"){
				//渠道 快递设置 快递设置
				that.quanxian2["50008"] = true;
				that.quanxian2["50008001"] = true;
				//渠道
				that.quanxian2["50000"] = true;
			} 
			 
			//公告设置 -------------------------------
			
			if(quanxian[i]["user_auth_id"] == "50009001"){
				//渠道 公告设置 公告设置
				that.quanxian2["50009"] = true;
				that.quanxian2["50009001"] = true;
				//渠道
				that.quanxian2["50000"] = true;
			} 
			
			//渠道 -------------------------------------------------------------
			
			//专柜 -------------------------------
			
			if(quanxian[i]["user_auth_id"] == "60001001"){
				//渠道 专柜 报价
				that.quanxian2["60001"] = true;
				that.quanxian2["60001001"] = true;
				//渠道
				that.quanxian2["60000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "60001002"){
				//渠道 专柜 待发货
				that.quanxian2["60001"] = true;
				that.quanxian2["60001002"] = true;
				//渠道
				that.quanxian2["60000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "60001003"){
				//渠道 专柜 发货
				that.quanxian2["60001"] = true;
				that.quanxian2["60001003"] = true;
				//渠道
				that.quanxian2["60000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "60001004"){
				//渠道 专柜 退回
				that.quanxian2["60001"] = true;
				that.quanxian2["60001004"] = true;
				//渠道
				that.quanxian2["60000"] = true;
			}
			 
			
			if(quanxian[i]["user_auth_id"] == "60001005"){
				//渠道 专柜 忽略
				that.quanxian2["60001"] = true;
				that.quanxian2["60001005"] = true;
				//渠道
				that.quanxian2["60000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "60001006"){
				//渠道 专柜 结算
				that.quanxian2["60001"] = true;
				that.quanxian2["60001006"] = true;
				//渠道
				that.quanxian2["60000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "60001007"){
				//渠道 专柜 已完成
				that.quanxian2["60001"] = true;
				that.quanxian2["60001007"] = true;
				//渠道
				that.quanxian2["60000"] = true;
			}
			
			if(quanxian[i]["user_auth_id"] == "60001008"){
				//渠道 专柜 已发货
				that.quanxian2["60001"] = true;
				that.quanxian2["60001008"] = true;
				//渠道
				that.quanxian2["60000"] = true;
			}
			 
			 
			
			localStorage.setItem('quanxian2',"") 
			
			localStorage.setItem('quanxian2',JSON.stringify(that.quanxian2))
		}
	}
	
	
	
}

export default authority
